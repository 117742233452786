@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    min-height: 100vh;
    display: flex;
    background-image: url(/static/media/background.e82390ac.jpg);
    background-position: center;
    background-size: cover;
}

@media screen and (max-width: 768px) {
    .App {
        background-position: right;
    }
 
}

@media screen and (max-width: 1024px) {
    .App {
        background-position: right;
    }
 
 
}

.linkTree {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.bot {
    padding: 10px;
    cursor: pointer;
    background-color: black;
    border-radius: 10px;
}



::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-thumb {
    background: rgba(236, 99, 99, 0.281);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
.messages {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
}

